import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { ActionIcon } from '~/components/atoms/action-icon'
import { Button } from '~/components/ui/button'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '~/components/ui/popover'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '~/components/ui/tabs'
import type { DateTimeSlot } from '~/domains/quotations/types/date-time-slot'
import { MultiDatePicker } from './multidate-picker'
import { RecurencePicker } from './recurence-picker'

export const DateTimeSlotPickerContent = ({
	onChange,
}: {
	onChange: (timeslots: DateTimeSlot[]) => void
}) => {
	return (
		<Tabs defaultValue='multiple' className='w-full'>
			<TabsList className='grid w-full grid-cols-2'>
				<TabsTrigger value='multiple'>Ajouter des dates</TabsTrigger>

				<TabsTrigger value='recurring'>Récurrence</TabsTrigger>
			</TabsList>

			<TabsContent value='multiple'>
				<MultiDatePicker onChange={onChange} />
			</TabsContent>

			<TabsContent value='recurring'>
				<RecurencePicker onChange={onChange} />
			</TabsContent>
		</Tabs>
	)
}

export const DateTimeSlotPicker = ({
	onChange,
}: {
	onChange: (timeslots: DateTimeSlot[]) => void
}) => {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Popover open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger asChild>
				<Button variant='outline'>
					<ActionIcon actionName='create' className='mr-2 text-sm' />

					{t('actions:add_date.btnLabel')}
				</Button>
			</PopoverTrigger>

			<PopoverContent className='z-50 w-auto'>
				<DateTimeSlotPickerContent
					onChange={(timeslots) => {
						onChange(timeslots)
						setIsOpen(false)
					}}
				/>
			</PopoverContent>
		</Popover>
	)
}
