import { CommandLoading } from 'cmdk'
import { Check, ChevronsUpDown, Trash2 } from 'lucide-react'
import { useRef, useState } from 'react'
import { Badge } from '~/components/ui/badge'
import { Button } from '~/components/ui/button'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from '~/components/ui/command'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '~/components/ui/popover'
import { ScrollArea } from '~/components/ui/scroll-area'
import { useEntriesList } from '~/hooks/admin-api/entry.hook'
import { cn } from '~/lib/utils'

export const MultiselectEntityCombobox = ({
	describeId,
	value,
	onChange,
}: {
	describeId: string
	value?: string[]
	onChange: (obj: string[]) => void
}) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const [isOpen, setIsOpen] = useState(false)

	const { data, isLoading } = useEntriesList(describeId, {})

	const onSelect = (item: any) => {
		const newValue = value?.includes(item.id)
			? value.filter((i) => i !== item.id)
			: [...(value || []), item.id]

		onChange(newValue)
	}

	return (
		<div ref={containerRef}>
			<Popover open={isOpen} onOpenChange={setIsOpen}>
				<PopoverTrigger asChild>
					<Button
						variant='outline'
						role='combobox'
						aria-expanded={isOpen}
						className='w-full justify-between gap-1'
					>
						<div className='flex flex-1 flex-row gap-2 truncate'>
							{data?.data
								?.filter((item) => value?.includes(item.id))
								.map((item) => (
									<Badge key={item.id}>{item.title || item.name}</Badge>
								))}
						</div>

						{!value?.length && ''}

						{value?.length ? (
							<button
								onClick={(e) => {
									e.preventDefault()
									onChange([])
								}}
							>
								<Trash2 className='ml-auto size-4 shrink-0' />
							</button>
						) : (
							<ChevronsUpDown className='ml-auto size-4 w-3 shrink-0 opacity-50' />
						)}
					</Button>
				</PopoverTrigger>

				<PopoverContent
					container={containerRef.current}
					className='w-[250px] p-0'
				>
					<Command>
						<CommandInput />

						{isLoading && <CommandLoading />}

						<CommandEmpty>Aucun résultat.</CommandEmpty>

						<ScrollArea className='h-[300px]'>
							<CommandGroup>
								{data?.data
									?.sort((a, b) => {
										if (value?.includes(a.id)) return -1

										return 0
									})
									.map((item) => (
										<CommandItem
											key={item.id}
											value={item.title || item.name}
											onSelect={() => onSelect(item)}
										>
											{item.title}

											<Check
												className={cn(
													'ml-auto h-4 w-4',
													value?.includes(item.id)
														? 'opacity-100'
														: 'opacity-0',
												)}
											/>
										</CommandItem>
									))}
							</CommandGroup>
						</ScrollArea>
					</Command>
				</PopoverContent>
			</Popover>
		</div>
	)
}
