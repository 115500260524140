import type { Zone } from '@cocoonspace/sdk-js/types/zone'
import { useTranslation } from 'next-i18next'
import { type ComponentRef, forwardRef } from 'react'
import type {
	Control,
	ControllerRenderProps,
	FieldValues,
} from 'react-hook-form'
import { MultiselectEntityCombobox } from '~/components/multiselect-entity-combobox'
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
} from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '~/components/ui/select'
import { ServicesFilter } from '../molecules/filters/services-filter'

export interface Filters {
	zone: Zone
	title: string
	capacity: string
	venues: string[]
	features: string[]
}

const ZoneFilter = forwardRef<
	ComponentRef<typeof SelectTrigger>,
	ControllerRenderProps<FieldValues, string>
>(({ value, onChange }, ref) => (
	<Select defaultValue={value} onValueChange={onChange}>
		<FormControl>
			<SelectTrigger ref={ref} className='w-[100px]'>
				<SelectValue />
			</SelectTrigger>
		</FormControl>

		<SelectContent>
			{[
				{ label: 'Paris', value: 'paris' },
				{ label: 'Lyon', value: 'lyon' },
			].map((item) => (
				<SelectItem key={item.value} value={item.value}>
					{item.label}
				</SelectItem>
			))}
		</SelectContent>
	</Select>
))

ZoneFilter.displayName = 'ZoneFilter'

const TitleFilter = forwardRef<
	ComponentRef<typeof Input>,
	ControllerRenderProps<FieldValues, string>
>(({ value, onChange }, ref) => (
	<Input ref={ref} value={value} className='w-[200px]' onChange={onChange} />
))

TitleFilter.displayName = 'TitleFilter'

const CapacityFilter = forwardRef<
	ComponentRef<typeof SelectTrigger>,
	ControllerRenderProps<FieldValues, string>
>(({ value, onChange }, ref) => (
	<Select defaultValue={value} onValueChange={onChange}>
		<FormControl>
			<SelectTrigger ref={ref} className='w-[80px]'>
				<SelectValue />
			</SelectTrigger>
		</FormControl>

		<SelectContent>
			<SelectItem value=''> </SelectItem>

			{[
				{ label: '1-4', value: '1-4' },
				{ label: '4-8', value: '4-8' },
				{ label: '8-12', value: '8-12' },
				{ label: '12-16', value: '12-16' },
				{ label: '16+', value: '16-' },
			].map((item) => (
				<SelectItem key={item.value} value={item.value}>
					{item.label}
				</SelectItem>
			))}
		</SelectContent>
	</Select>
))

CapacityFilter.displayName = 'CapacityFilter'

export const TableFilters = ({
	control,
}: {
	control: Control<Filters>
}) => {
	const { t } = useTranslation()

	return (
		<>
			{(['zone', 'venues', 'title', 'capacity', 'features'] as const).map(
				(key) => (
					<FormField
						key={key}
						control={control}
						name={key}
						render={({ field }) => (
							<FormItem>
								<FormLabel>{t(`attributesLabels:${key}`)}</FormLabel>

								{key === 'zone' && <ZoneFilter {...field} />}

								{key === 'venues' && (
									<div className='w-[250px]'>
										<MultiselectEntityCombobox
											describeId='venues'
											value={field.value as string[]}
											onChange={field.onChange}
										/>
									</div>
								)}

								{key === 'title' && <TitleFilter {...field} />}

								{key === 'capacity' && <CapacityFilter {...field} />}

								{key === 'features' && (
									<ServicesFilter
										value={field.value as string[]}
										className='w-[250px]'
										onChange={field.onChange}
									/>
								)}
							</FormItem>
						)}
					/>
				),
			)}
		</>
	)
}
