import { cocoonFeatures } from '@cocoonspace/shared/config/cocoon-features-config'
import { Check, ChevronsUpDown, X } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRef, useState } from 'react'
import { Badge } from '~/components/ui/badge'
import { Button } from '~/components/ui/button'
import { Command, CommandGroup, CommandItem } from '~/components/ui/command'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '~/components/ui/popover'
import { ScrollArea } from '~/components/ui/scroll-area'
import { cn } from '~/lib/utils'

interface ServicesFilterProps {
	value?: string[]
	onChange: (obj: string[]) => void
	className?: string
}

const filterableFeatures = cocoonFeatures
	.filter((feat) => feat.isSearchFilter)
	.map((feat) => ({
		...feat,
		title: `values:values.features.${feat.id}`,
	}))

export const ServicesFilter = ({
	value,
	onChange,
	...props
}: ServicesFilterProps) => {
	const servicesFilterRef = useRef<HTMLDivElement>(null)
	const [isOpen, setIsOpen] = useState(false)

	const { t } = useTranslation()

	const onSelect = (item: any) => {
		const newValue = value?.includes(item.id)
			? value.filter((i) => i !== item.id)
			: [...(value || []), item.id]

		onChange(newValue)
	}

	return (
		<div ref={servicesFilterRef} {...props}>
			<Popover open={isOpen} onOpenChange={setIsOpen}>
				<PopoverTrigger asChild>
					<Button
						variant='outline'
						role='combobox'
						aria-expanded={isOpen}
						className='w-full justify-between gap-1'
					>
						<div className='flex flex-1 flex-row gap-2 truncate'>
							{filterableFeatures
								.filter((item) => value?.includes(item.id))
								.map((item) => (
									<Badge key={item.id}>{t(item.title)}</Badge>
								))}
						</div>

						{!!value?.length ? (
							<div
								onClick={(e) => {
									e.preventDefault()
									onChange([])
								}}
							>
								<X className='ml-auto size-4 opacity-50' />
							</div>
						) : (
							<ChevronsUpDown className='ml-auto size-4 w-3 opacity-50' />
						)}
					</Button>
				</PopoverTrigger>

				<PopoverContent
					container={servicesFilterRef.current}
					className='w-[250px] p-0'
				>
					<Command>
						<ScrollArea>
							<CommandGroup>
								{filterableFeatures.map((item) => (
									<CommandItem
										key={item.id}
										value={item.id}
										data-testid={`feature-${item.id}`}
										className='flex w-full cursor-pointer items-center gap-x-2 bg-inherit py-2 text-center text-sm'
										onSelect={() => onSelect(item)}
									>
										<div className='flex w-[44px] items-center justify-center'>
											<Image
												src={`/images/features/${item.id}.svg`}
												alt={item.id}
												width='44'
												height='35'
												className={item.id === 'elevator' ? 'ml-[3px]' : ''}
											/>
										</div>

										{t(item.title)}

										<Check
											className={cn(
												'mr-2 ml-auto h-4 w-4',
												value?.includes(item.id) ? 'opacity-100' : 'opacity-0',
											)}
										/>
									</CommandItem>
								))}
							</CommandGroup>
						</ScrollArea>
					</Command>
				</PopoverContent>
			</Popover>
		</div>
	)
}
