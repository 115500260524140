import type { Event } from '@cocoonspace/sdk-js/types/event'
import { useReducer } from 'react'

import { genDateTimeUniqueKey } from '../utils/gen-date-time-unique-key'

export type Slot = Pick<Event, 'day' | 'start' | 'end'> & { space_id: string }

interface SlotState {
	slots: Omit<Slot, 'space_id'>[]
	selectedSlots: Slot[]
}

interface AddSlot {
	type: 'add'
	payload: Omit<Slot, 'space_id'>[]
}

interface RemoveSlot {
	type: 'remove'
	payload: Omit<Slot, 'space_id'>
}

interface SelectSlot {
	type: 'select'
	payload: Slot[]
}

interface UnselectSlot {
	type: 'unselect'
	payload: Slot
}

interface UnselectRowSlot {
	type: 'unselect-row'
	payload: string
}

export type SlotActions =
	| AddSlot
	| RemoveSlot
	| SelectSlot
	| UnselectSlot
	| UnselectRowSlot

const slotsReducer = (state: SlotState, action: SlotActions) => {
	switch (action.type) {
		case 'add':
			const slotToAdd = action.payload.filter((slot) => {
				const res = state.slots.filter(
					(stateSlot) =>
						genDateTimeUniqueKey(stateSlot) === genDateTimeUniqueKey(slot),
				)

				return !res.length
			})

			return {
				...state,
				slots: [...state.slots, ...slotToAdd],
			}

		case 'remove':
			return {
				...state,
				slots: state.slots.filter(
					(slot) =>
						genDateTimeUniqueKey(slot) !== genDateTimeUniqueKey(action.payload),
				),
			}

		case 'select':
			const selectedSlotToAdd = action.payload
				.filter((slot) => {
					const res = state.selectedSlots
						.filter((selectedSlot) => selectedSlot.space_id === slot.space_id)
						.filter(
							(selectedSlot) =>
								genDateTimeUniqueKey(selectedSlot) ===
								genDateTimeUniqueKey(slot),
						)

					return !res.length
				})
				.map((slot) => ({
					space_id: slot.space_id,
					day: slot.day,
					start: slot.start,
					end: slot.end,
				}))

			return {
				...state,
				selectedSlots: [...state.selectedSlots, ...selectedSlotToAdd],
			}

		case 'unselect':
			return {
				...state,
				selectedSlots: state.selectedSlots.filter((slot) => {
					const currentEl =
						genDateTimeUniqueKey(slot) ===
							genDateTimeUniqueKey(action.payload) &&
						slot.space_id === action.payload.space_id

					return !currentEl
				}),
			}

		case 'unselect-row':
			return {
				...state,
				selectedSlots: state.selectedSlots.filter(
					(slot) => slot.space_id !== action.payload,
				),
			}
	}
}

export const useTimeSlots = () => {
	const [slotsState, dispatchSlots] = useReducer(slotsReducer, {
		slots: [],
		selectedSlots: [],
	})

	return {
		slotsState,
		dispatchSlots,
	}
}
